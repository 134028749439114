@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  @apply bg-neutral-900 overflow-x-hidden w-full relative;
}

#root {
  @apply overflow-x-hidden w-full relative;
}


.content {
  @apply max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 py-16 md:py-28;
}

.grid-background {
  background-size: 40px 40px;
  background-image: linear-gradient(to right, rgb(151, 33, 33) 1px, transparent 1px),
    linear-gradient(to bottom, rgb(151, 33, 33) 1px, transparent 1px);
}

.text-outlined-muted {
  -webkit-text-fill-color: #171717;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #737373;
}

.stack-2 {
  box-shadow: 20px 20px 0 -1px rgb(23 23 23), 20px 20px 0 rgb(38 38 38), 40px 40px 0 -1px rgb(23 23 23), 40px 40px 0 rgb(38 38 38);
}

.stack-1 {
  box-shadow: 10px 10px 0 -1px rgb(23 23 23), 10px 10px 0 rgb(38 38 38);
}

.shadow-md {
  box-shadow: var(--tw-ring-offset-shadow,0 0 #000),var(--tw-ring-shadow,0 0 #000),var(--tw-shadow) !important;
}

.shadow-md {
  --tw-shadow: 3px 3px 0 rgb(38 38 38) !important;
  --tw-shadow-colored: 3px 3px 0 var(--tw-shadow-color)!important;
}

.testimonial-card {
  @apply flex flex-col justify-between stack-1 rounded-sm bg-neutral-800 text-white p-4
}

.creator-card {
  @apply rounded-sm overflow-hidden absolute stack-1
}

.news-card {
  @apply flex flex-col stack-1 rounded-sm bg-neutral-800 text-white overflow-hidden
}
